// Función para obtener datos desde el archivo PHP
async function fetchData() {
  try {
    const response = await fetch("getData.php"); // Realizar solicitud a getData.php
    const data = await response.json(); // Convertir la respuesta a JSON

    // Verificar si hay un error
    if (data.error) {
      document.querySelectorAll(".address").forEach((element) => {
        element.innerText = `Error: ${data.error}`;
      });
      return;
    }

    // Obtener los campos de los datos
    const fields = data.fields;

    // Actualizar el texto del campo restaurantAbout
    if (fields.restaurantAbout && fields.restaurantAbout.stringValue) {
      document
        .querySelectorAll(".description_about")
        .forEach((descriptionElement) => {
          descriptionElement.innerHTML = fields.restaurantAbout.stringValue; // Utiliza innerHTML para preservar etiquetas <br>
        });
    }

    //Actualizar los textos del nombre del restaurante
    if (fields.restName && fields.restName.stringValue) {
      document.querySelectorAll(".location_name").forEach((location_name) => {
        location_name.innerText = fields.restName.stringValue;
      });
    }

    if (fields.address && fields.address.stringValue) {
      // Actualizar los textos de las direcciones si existen
      document.querySelectorAll(".address").forEach((address) => {
        address.innerText = fields.address.stringValue; // Mostrar la dirección
      });
    }

    // Asignar el evento de clic al botón para abrir el enlace
    if (fields.linkLocation && fields.linkLocation.stringValue) {
      document.querySelectorAll(".address-link").forEach((button) => {
        button.addEventListener("click", function () {
          window.open(fields.linkLocation.stringValue, "_blank"); // Abrir el enlace en una nueva pestaña
        });
      });
    }

    // Actualizar los números de teléfono y agregar evento de clic
    if (fields.phoneNumber && fields.phoneNumber.stringValue) {
      const phoneNumber = fields.phoneNumber.stringValue; // Obtener el número de teléfono
      const formattedPhoneNumber = formatPhoneNumber(phoneNumber); // Formatear el número de teléfono

      document.querySelectorAll(".phone").forEach((phoneElement) => {
        phoneElement.textContent = formattedPhoneNumber; // Mostrar el número de teléfono formateado

        // Agregar evento de clic para abrir el enlace de llamada telefónica
        phoneElement.addEventListener("click", function () {
          window.location.href = "tel:" + phoneNumber; // Redirigir a un enlace de llamada
        });
      });
    }

    // Actualizar los correos electrónicos y agregar evento de clic
    if (fields.email && fields.email.stringValue) {
      const email = fields.email.stringValue; // Obtener el correo electrónico

      document.querySelectorAll(".email").forEach((emailElement) => {
        emailElement.textContent = email; // Mostrar el correo electrónico

        // Agregar evento de clic para abrir el cliente de correo
        emailElement.addEventListener("click", function () {
          window.location.href = "mailto:" + email; // Redirigir a un enlace de correo
        });
      });
    }

    // Actualizar días y horarios dinámicamente desde la base de datos
    if (fields.hours && fields.hours.mapValue) {
      const hoursData = fields.hours.mapValue.fields;

      // Días de la semana en el orden de lunes a domingo
      const daysOrder = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];

      // Ordenar los días de la base de datos en el orden de lunes a domingo
      const sortedDays = daysOrder.map((day) => {
        return { day, hours: hoursData[day] ? hoursData[day].stringValue : "" };
      });

      sortedDays.forEach((entry, index) => {
        const dayElements = document.querySelectorAll(`.d${index + 1}`);
        const hourElements = document.querySelectorAll(`.h${index + 1}`);

        dayElements.forEach((dayElement) => {
          dayElement.textContent = entry.day; // Asignar el nombre del día en el orden correcto
        });

        hourElements.forEach((hourElement) => {
          hourElement.textContent = entry.hours; // Asignar el horario correspondiente
        });
      });
    }

    //Actualizar las redes sociales y agregar evento de click
    if (fields.facebook && fields.facebook.stringValue) {
      document.querySelectorAll(".fb").forEach((span) => {
        span.addEventListener("click", function () {
          window.open(fields.facebook.stringValue, "_blank");
        });
      });
    }

    if (fields.instagram && fields.instagram.stringValue) {
      document.querySelectorAll(".ig").forEach((span) => {
        span.addEventListener("click", function () {
          window.open(fields.instagram.stringValue, "_blank");
        });
      });
    }

    if (fields.yelp && fields.yelp.stringValue) {
      document.querySelectorAll(".yp").forEach((span) => {
        span.addEventListener("click", function () {
          window.open(fields.yelp.stringValue, "_blank");
        });
      });
    }

    if (fields.tiktok && fields.tiktok.stringValue) {
      document.querySelectorAll(".tk").forEach((span) => {
        span.addEventListener("click", function () {
          window.open(fields.tiktok.stringValue, "_blank");
        });
      });
    }

    //Actualizar las ordenes en linea y agregar evento de click
    if (fields.pick_up && fields.pick_up.stringValue) {
      document.querySelectorAll(".order-pickup").forEach((span) => {
        span.addEventListener("click", function () {
          window.open(fields.pick_up.stringValue, "_blank"); // Abrir el enlace en una nueva pestaña
        });
      });
    }

    if (fields.delivery1 && fields.delivery1.stringValue) {
      document.querySelectorAll(".order-delivery1").forEach((span) => {
        span.addEventListener("click", function () {
          window.open(fields.delivery1.stringValue, "_blank"); // Abrir el enlace en una nueva pestaña
        });
      });
    }

    if (fields.delivery2 && fields.delivery2.stringValue) {
      document.querySelectorAll(".order-delivery2").forEach((span) => {
        span.addEventListener("click", function () {
          window.open(fields.delivery2.stringValue, "_blank"); // Abrir el enlace en una nueva pestaña
        });
      });
    }

    if (fields.delivery3 && fields.delivery3.stringValue) {
      document.querySelectorAll(".order-delivery3").forEach((span) => {
        span.addEventListener("click", function () {
          window.open(fields.delivery3.stringValue, "_blank"); // Abrir el enlace en una nueva pestaña
        });
      });
    }

    //Actualizar el boton google reviews y agrega evento de click
    if (fields.googleReviewsLink && fields.googleReviewsLink.stringValue) {
      document.querySelectorAll(".desk-google").forEach((button) => {
        button.addEventListener("click", function () {
          window.open(fields.googleReviewsLink.stringValue, "_blank"); // Abrir el enlace en una nueva pestaña
        });
      });
    }

    // Actualizar el contenido del iframe
    if (
      fields.restaurantIframeGoogleLink &&
      fields.restaurantIframeGoogleLink.stringValue
    ) {
      document.querySelectorAll(".iframe").forEach((iframeContainer) => {
        iframeContainer.innerHTML =
          fields.restaurantIframeGoogleLink.stringValue; // Inserta el iframe como HTML
      });
    }
  } catch (error) {
    document.querySelectorAll(".address").forEach((element) => {
      element.innerText = `Error al obtener los datos: ${error.message}`;
    });
  }
}

// Función para formatear el número de teléfono en el formato (000) 000-0000
function formatPhoneNumber(phoneNumber) {
  // Eliminar cualquier carácter que no sea un dígito
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  // Extraer los diferentes componentes del número de teléfono
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  // Si coincide con el formato esperado, devolver el número formateado
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  // Si no coincide, devolver el número original sin formatear
  return phoneNumber;
}

// Ejecutar la función fetchData sin ninguna restricción de URL
fetchData();
